<template>
  <div class="bg-white w-full max-w-4xl px-5 py-4 rounded-md mb-2">
    <div class="flex sm:flex-row gap-3 text-center md:text-left">
      <div class="h-16 w-16 hidden md:block" v-html="powerbank.Image"></div>    
      <div class="grow">
        <div class="md:hidden w-1/2 mx-auto mb-4" v-html="powerbank.Image"></div>  
        <span class="text-black-800 font-bold text-md">          
          <a :href="powerbank.URL">
            {{ powerbank.Brand }}
          </a>
           - {{ powerbank.Name }}
        </span>          
        <div class="flex justify-center md:justify-start gap-3 mt-2 mb-2">
          <span class="text-slate-600 text-sm flex gap-1 items-center"> 
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg> {{ powerbank.Capacity }}mAh
          </span>
          <span class="text-slate-600 text-sm flex gap-1 items-center"> 
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg> ${{ powerbank.Price }}
          </span>
        </div>
      </div>
      <div class="md:ml-auto hidden md:block">
        <button class="flex-1 px-6 py-2 font-semibold select-none rounded-md text-indigo-700 border border-indigo-500 bg-transparent hover:bg-indigo-50">Buy now on amazon</button>
      </div>
    </div>
  </div>  
</template>

<script>


export default {
  name: 'PowerBankList',
  props: {
    msg: String,
    powerbank: {
      type: Object,
      default() {
        return {};
      },
    },
  },
}
</script>
