<template>
  <div class="drawer">
    <input id="my-drawer" type="checkbox" class="drawer-toggle" />
    <div class="drawer-content">
      <!-- Page content here -->      
      <section class="bg-slate-100" id="content">
        <div class="container max-w-5xl flex flex-col md:flex-row mx-auto items-start">      
          <div class="flex flex-col w-full md:w-1/5 p-3">
            <label for="my-drawer" class="btn drawer-button md:hidden">Show filters</label>
            <div class="hidden md:inline-block">
              <h2 class="text-xl font-medium mb-4">Filters</h2>
              <h3>Brand</h3>
              <div
                v-for="brand in brandOptions"
                :key="brand.brand"            
                > 
                  <label class="form-check-label inline-block text-gray-800" v-bind:for="brand">
                    {{ brand }}
                  </label>
                  <input
                      class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      name="brandCB"
                      v-bind:value="brand"
                      v-model="filterByBrand"
                      v-bind:id="brand"
                    >
                </div>
              
                <h3 class="mt-5">Capacity</h3>
                <div
                  v-for="capacity in capacityOptions"
                  :key="capacity.key"
                >
                <input
                  type="radio"
                  name="capacityRadio"
                  :id="capacity.key"
                  :value="capacity.key"
                  v-model="filterByCapacity"
                  :checked="capacity.checked"
                  >
                  <label class="ml-1" v-bind:for="capacity.key">{{ capacity.label }}</label>
                </div>
              </div>
          </div> 
          <div class="flex flex-col md:w-4/5 p-3">
            <div class="overflow-x-auto sm:rounded-lg">
                <div class="inline-block min-w-full align-middle">
                  <power-banks-list class="bg-purple"
                    v-for="powerbank in filteredPowerBanksList"
                    :key="powerbank.ASIN"
                    :powerbank="powerbank"
                  />
                </div>
              </div>
          </div>
        </div>
      </section>
    </div> 
    <div class="drawer-side">
      <label for="my-drawer" class="drawer-overlay"></label>      
      <span class="menu p-4 overflow-y-auto w-80 bg-base-100 text-base-content">
        <h3>Brand</h3>
        <div
          v-for="brand in brandOptions"
          :key="brand.brand"
          > 
            <label class="form-check-label inline-block text-gray-800" v-bind:for="brand">
              {{ brand }}
            </label>
            <input
                class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                name="brandCB"
                :value="brand"
                v-model="filterByBrand"
                :id="brand"
              >
          </div>
          <h3 class="mt-5">Capacity</h3>
          
          <div
            v-for="capacity in capacityOptions"
            :key="capacity.key"
            >
            <input
              type="radio"
              name="capacityRadioMob"
              :id="capacity.key"
              :value="capacity.key"
              v-model="filterByCapacity"
              :checked="capacity.checked"
              >
              <label class="ml-1" v-bind:for="capacity.key">{{ capacity.label }}</label>
            </div>
        </span>
    </div>
  </div>
</template>

<script>

import PowerBanksList from './components/PowerBanksList.vue'
// import BrandFilter from './components/BrandFilter.vue'
/* import axios from "axios"; */

export default {
  name: 'App',  
  components: {
    PowerBanksList
  },
  data() {
    return {
      powerBanksList:[],
      filterByBrand:[],
      filterByCapacity:[0],
      // Populate unique brand options for filtering dynamically from the data
      brandOptions:[],
      capacityOptions: [
        { label: 'All', key: '0', checked: true },
        { label: '5000mAh +', key: '5000', checked: false },
        { label: '10000mAh +', key: '10000', checked: false },
        { label: '20000mAh +', key: '20000', checked: false },
        { label: '25000mAh +', key: '25000',  checked: false },
      ],
    }
  },
  created() {
    document.title = "Best powerbanks - Curated list of the best portable chargers";
        
    let data = require('../data/amazon-us.json');
    this.powerBanksList = data;
    const filterArr = [];

    //This is to get unique array of brand names form data
    Object.keys(this.powerBanksList).forEach( (prop) => {
      filterArr.push(this.powerBanksList[prop].Brand);
    });
    const uniqueBrands = [...new Set(filterArr)];
    this.brandOptions  = uniqueBrands;
    // TODO: Doesn't seem right why I need to set this again
    this.filterByBrand  = this.brandOptions;
  },
  methods: {
    
  },
  computed: {
    filteredPowerBanksList() {
      return this.powerBanksList
          .filter(powerbank => this.filterByBrand.includes(powerbank.Brand))
          .filter(powerbank => powerbank.Capacity >= parseInt(this.filterByCapacity) )
    },
  }
}

</script>

<style>

</style>
